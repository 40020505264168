import { createTheme } from "@mui/material";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 390,
      md: 980,
      lg: 1092,
      xl: 1440,
    },
  },
  palette: {
    primary: {
      main: "#0071BA",
      light: "#6DC7DD",
    },
    error: {
      main: "#D23231",
    },
    success: {
      main: "#169A41",
    },
    grey: {
      servier_1: "#333333",
      servier_2: "#4F4F4F",
      servier_3: "#767676",
      servier_4: "#BDBDBD",
      servier_5: "#E0E0E0",
      servier_6: "#F2F2F2",
    },
    white: "#FFFFFF",
    servier: {
      mainBlue: "#0071BA",
      lightBlue: "#6DC7DD",
      blue1: "#033878",
      blue2: "#CEEAF3",
      blue3: "#EAF6FB",
      purple1: "#493C90",
      purple2: "#96368B",
      pink: "#EA5284",
      red: "#E73458",
      orange: "#F39325",
      yellow: "#FDC41F",
      green: "#95C11F",
      blueDuck: "#00B3BB",
    },
  },
  link: {
    textDecoration: "none",
  },
  typography: {
    fontFamily: ["Roboto", "sans-serif"].join(","),
    textDecoration: "none",
    ourLocations: {
      //styleName: SU/36 Pt/Bold;
      fontFamily: "Roboto",
      fontSize: "28px",
      fontStyle: "normal",
      fontWeight: "900",
      lineHeight: "35px",
      letterSpacing: "0em",
      textAlign: "center",
      color: "white",
    },
    h1: {
      //styleName: SU/36 Pt/Bold;
      fontFamily: "Roboto",
      fontSize: "36px",
      fontStyle: "normal",
      fontWeight: "900",
      lineHeight: "42px",
      letterSpacing: "0em",
      textAlign: "left",
    },
    h2: {
      //styleName: SU/28 Pt/Bold;
      fontFamily: "Roboto",
      fontSize: "28px",
      fontStyle: "normal",
      fontWeight: "900",
      lineHeight: "35px",
      letterSpacing: "0em",
      textAlign: "left",
    },
    h3: {
      //styleName: SU/24 Pt/Bold;
      fontFamily: "Roboto",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: "900",
      lineHeight: "28px",
      letterSpacing: "0em",
      textAlign: "left",
    },
    subtitle1: {
      //styleName: SU/16 Pt/Bold Capital;
      fontFamily: "Roboto",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "900",
      lineHeight: "19px",
      letterSpacing: "0em",
      textAlign: "left",
    },
    bodyStrong: {
      //styleName: SU/18 Pt/Bold;
      fontFamily: "Roboto",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: "900",
      lineHeight: "25px",
      letterSpacing: "0em",
      textAlign: "left",
    },
    body1: {
      //styleName: SU/16 Pt/Regular;
      fontFamily: "Roboto",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "22px",
      letterSpacing: "0em",
      textAlign: "left",
    },
    small1: {
      //styleName: SU/12 Pt/Regular;
      fontFamily: "Roboto",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "14px",
      letterSpacing: "0.03em",
      textAlign: "left",
    },
    textButton: {
      //styleName: SU/14 Pt/Regular;
      fontFamily: "Roboto",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "16px",
      letterSpacing: "0.03em",
      textAlign: "left",
    },
    body3: {
      fontWeight: 700,
      fontSize: "1rem",
    },
    footerStrong: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "900",
      lineHeight: "18.75px",
      letterSpacing: "0em",
      textAlign: "left",
    },
    footerSmall: {
      fontFamily: "Roboto",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "16.41px",
      letterSpacing: "0em",
      textAlign: "left",
    },
    footerSmallStrong: {
      fontFamily: "Roboto",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "16.41px",
      letterSpacing: "0em",
      textAlign: "left",
    },
    imageMobileTitle: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "900",
      lineHeight: "22px",
      letterSpacing: "0em",
      textAlign: "left",
    },
    imageMobileDesc: {
      fontFamily: "Roboto",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "16.41px",
      letterSpacing: "0em",
      textAlign: "left",
    },
  },
});

export default theme;
