// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-courses-index-js": () => import("./../../../src/pages/courses/index.js" /* webpackChunkName: "component---src-pages-courses-index-js" */),
  "component---src-pages-courses-strapi-course-slug-js": () => import("./../../../src/pages/courses/{StrapiCourse.Slug}.js" /* webpackChunkName: "component---src-pages-courses-strapi-course-slug-js" */),
  "component---src-pages-forgotten-password-js": () => import("./../../../src/pages/forgotten-password.js" /* webpackChunkName: "component---src-pages-forgotten-password-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-live-events-index-js": () => import("./../../../src/pages/live-events/index.js" /* webpackChunkName: "component---src-pages-live-events-index-js" */),
  "component---src-pages-live-events-strapi-live-event-slug-js": () => import("./../../../src/pages/live-events/{StrapiLiveEvent.Slug}.js" /* webpackChunkName: "component---src-pages-live-events-strapi-live-event-slug-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-medical-informations-index-js": () => import("./../../../src/pages/medical-informations/index.js" /* webpackChunkName: "component---src-pages-medical-informations-index-js" */),
  "component---src-pages-medical-informations-strapi-editorial-content-slug-js": () => import("./../../../src/pages/medical-informations/{StrapiEditorialContent.Slug}.js" /* webpackChunkName: "component---src-pages-medical-informations-strapi-editorial-content-slug-js" */),
  "component---src-pages-new-password-js": () => import("./../../../src/pages/new-password.js" /* webpackChunkName: "component---src-pages-new-password-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-news-strapi-editorial-content-slug-js": () => import("./../../../src/pages/news/{StrapiEditorialContent.Slug}.js" /* webpackChunkName: "component---src-pages-news-strapi-editorial-content-slug-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-products-strapi-editorial-content-slug-js": () => import("./../../../src/pages/products/{StrapiEditorialContent.Slug}.js" /* webpackChunkName: "component---src-pages-products-strapi-editorial-content-slug-js" */),
  "component---src-pages-profile-edit-js": () => import("./../../../src/pages/profile-edit.js" /* webpackChunkName: "component---src-pages-profile-edit-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-servier-university-js": () => import("./../../../src/pages/servier-university.js" /* webpackChunkName: "component---src-pages-servier-university-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */)
}

