module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[],"uri":"https://app-servier-university-bo-staging.azurewebsites.net/graphql"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","es"],"defaultLanguage":"en","generateDefaultLanguagePage":true,"siteUrl":"http://localhost:8000","redirect":true,"i18nextOptions":{"defaultNS":"translations","debug":false,"lowerCaseLng":true,"saveMissing":false,"interpolation":{"escapeValue":false},"nsSeparator":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d47b9b0270893d8b9920ac5e5420243c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-material-ui-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PX2SS9H","gtmAuth":"VWXNQapmj4UJk3HISLJa6w","gtmPreview":"env-3","includeInDevelopment":false,"defaultDataLayer":{"type":"function","value":"function () {\n          let user = {};\n          let user_speciality = [];\n          try {\n            user = window?.localStorage?.getItem(\"gatsbyUser\")\n              ? JSON.parse(window.localStorage.getItem(\"gatsbyUser\"))\n              : {};\n            user_speciality = JSON.parse(user.areasOfExpertise);\n          } catch (error) {\n            console.warn(\"GTM init: user: %o\", user);\n          }\n          return {\n            page_connect: !!user.token ? \"login\" : \"logout\",\n            page_langage:\n              window.location.pathname.split(\"/\")[0] || \"en\" /* en|es */,\n            page_url: window.location.toString(),\n            /* transform ?search=12&anything=else&other to { search: 12, anything: 'else', other: undefined } */\n            search_term: window.location.search\n              .slice(1) /* remove ? */\n              .split(\"&\") /* [ \"search=12\", \"anything=else\", \"other=thing\" ] */\n              .map((keyvalue) =>\n                keyvalue.split(\"=\")\n              ) /* [ [\"search\", \"12\"], [\"anything\", \"else\"], [\"other\"] ] */\n              .reduce((acc, value) => {\n                /* { search: 12, anything: 'else', other: undefined } */\n                acc[value[0]] = value[1];\n                return acc;\n              }, {})[\"search\"],\n            user_type: user.profession || \"\",\n            user_speciality,\n            user_ids: user.id || \"\",\n          };\n        }"},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["AW-10806326984 "]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
