const { CircularProgress } = require("@mui/material");
const { Suspense } = require("react");
const React = require("react");
// Wraps every page in a component
exports.wrapPageElement = ({ element, props }) => {
  return (
    <Suspense
      fallback={
        <CircularProgress
          size="6rem"
          style={{ marginLeft: "50%", marginTop: "10%" }}
        />
      }
      {...props}
    >
      {element}
    </Suspense>
  );
};
